(function (window, $, undefined) {
    "use strict";

    var fixedHeaderRemoveThreshold = 10,
        lastScrollPos = 0;
    var fixedHeader = {
        headerOffset: 0,
        headerGroup: function () {
            return $("#HeaderGroup");
        },
        bodyGroup: function () {
            return $("#BodyWrap");
        },
        divMobileView: function () {
            return $("#divMobileView");
        },
        isMobileView: function () {
            if (typeof window.IsMobileView == "function") {
                return window.IsMobileView();
            }
            return fixedHeader.divMobileView().is(":visible");
        },
        divTabletView: function () {
            return $("#divTabletView");
        },
        isTabletView: function () {
            if (typeof window.IsTabletView == "function") {
                return window.IsTabletView();
            }
            return fixedHeader.divTabletView().is(":visible");
        },
        initiateScroll: function () {
            $(window).scroll(function () {
                fixedHeader.fixedHeaderScroll();
            });
        },
        showHeader: function () {
            var $bodyGroup = fixedHeader.bodyGroup();
            $bodyGroup.addClass("headerFix");
        },
        hideHeader: function () {
            var $headerGroup = fixedHeader.headerGroup();
            var $bodyGroup = fixedHeader.bodyGroup();
            $headerGroup.hide();
            $bodyGroup.removeClass("headerFix");
            if ($("#MobtxtSearch").is(":focus")) {
                $("#MobtxtSearch").blur();
            }
        },
        fixedHeaderScroll: function () {
            var scrollTopValue = $(window).scrollTop();
            if (scrollTopValue > lastScrollPos && scrollTopValue > fixedHeaderRemoveThreshold) {
                fixedHeader.hideHeader();
            }
            if (scrollTopValue < lastScrollPos) {
                fixedHeader.showHeader();
            }
            if ($(".persistentLevel2Selection").length > 0 && scrollTopValue < lastScrollPos) {
                fixedHeader.headerGroup().toggleClass("stickyHeader", scrollTopValue > lastScrollPos);
            } else {
                fixedHeader.headerGroup().toggleClass("stickyHeader", fixedHeader.headerOffset < $(window).scrollTop());
            }
            lastScrollPos = scrollTopValue;
        },
        init: function () {
            var headerGroup = fixedHeader.headerGroup();
            if (headerGroup.length == 0) return;
            fixedHeader.headerOffset = fixedHeader.headerGroup().offset().top;
            fixedHeader.initiateScroll();
        },
    };

    $(document).ready(function () {
        fixedHeader.init();
    });
})(window, window.jQuery);
