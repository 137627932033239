/*!
 * Responsive Switch 0.9.1 | @danielfilzhut | MIT/GPL2 Licensed
 */

var responsiveSwitch = (function () {
    // Screen configuration
    var responsiveSwitchBreakpoint = parseInt(window.responsiveSwitchBreakpoint) || 1022;

    // Internal vars
    var rsLinkCookieName = "ResponsiveSwitch";
    var attrViewportOriginal = "content";
    var attrViewportBackup = "data-rs";
    var attrLinkToDesktop = "data-link-desktop";
    var attrLinkToResponsive = "data-link-responsive";
    var attrAlwaysVisible = "data-always-visible";

    // Class names
    var linkClassName = "rs-link";
    var linkToDesktopClassName = "rs-link-to-desktop";
    var linkToResponsiveClassName = "rs-link-to-responsive";
    var linkInactiveClassName = "rs-link-inactive";
    var linkActiveClassName = "rs-link-active";

    // Switch viewport
    function rsViewport(setCookie) {
        var viewportNode = document.querySelector('meta[name="viewport"]');
        var switchMode = "to_desktop";

        // Backup original viewport
        if (viewportNode.getAttribute(attrViewportBackup) == undefined) {
            viewportNode.setAttribute(attrViewportBackup, viewportNode.getAttribute(attrViewportOriginal));
        }

        // Switch to desktop version
        if (viewportNode.getAttribute(attrViewportOriginal) == viewportNode.getAttribute(attrViewportBackup)) {
            if (setCookie) {
                document.cookie = rsLinkCookieName + "=true;path=/";
            }
            /*if (document.documentElement.clientWidth != window.innerWidth) {
                document.location.href = getReloadUrl();
            } else {*/
            viewportNode.setAttribute(
                attrViewportOriginal,
                "width=" +
                    responsiveSwitchBreakpoint +
                    "px, initial-scale=" +
                    document.documentElement.clientWidth / responsiveSwitchBreakpoint,
            );
            /*}*/
        }

        // Switch to responsive version
        else {
            switchMode = "to_responsive";
            document.cookie = rsLinkCookieName + "=false;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";
            if (document.documentElement.clientWidth != window.innerWidth) {
                document.location.href = getReloadUrl();
            } else {
                viewportNode.setAttribute(attrViewportOriginal, viewportNode.getAttribute(attrViewportBackup));
            }
        }

        window.parent.dataLayer.push({
            event: "responsiveSwitch",
            responsiveSwitchDirection: switchMode,
        });

        return false;
    }

    function rsViewportDesktopOnly() {
        var viewportNode = document.querySelector('meta[name="viewport"]');

        // Backup original viewport
        if (viewportNode.getAttribute(attrViewportBackup) == undefined) {
            viewportNode.setAttribute(attrViewportBackup, viewportNode.getAttribute(attrViewportOriginal));
        }

        // Switch to desktop version
        if (viewportNode.getAttribute(attrViewportOriginal) == viewportNode.getAttribute(attrViewportBackup)) {
            document.cookie = rsLinkCookieName + "=true;path=/";
            /*if (document.documentElement.clientWidth != window.innerWidth) {
                document.location.href = getReloadUrl();
            } else {*/
            viewportNode.setAttribute(
                attrViewportOriginal,
                "width=" +
                    responsiveSwitchBreakpoint +
                    "px, initial-scale=" +
                    document.documentElement.clientWidth / responsiveSwitchBreakpoint,
            );
            /*}*/
        }

        return false;
    }

    // Switch to correct link title
    var linksBound = false;
    function rsLink() {
        var force = true;
        if (typeof sd_ForceDesktop === "undefined") {
            force = false;
        }
        var linkNodes = document.querySelectorAll("." + linkClassName);
        for (var i = 0; i < linkNodes.length; i++) {
            var linkNode = linkNodes[i];
            if (linkNode != undefined) {
                // Remove classes from link
                removeClass(linkNode, linkToResponsiveClassName);
                removeClass(linkNode, linkToDesktopClassName);
                removeClass(linkNode, linkInactiveClassName);
                removeClass(linkNode, linkActiveClassName);

                // Switch link to DESKTOP version
                if (isCookieSet() || force) {
                    addClass(linkNode, linkToResponsiveClassName);
                    addClass(linkNode, linkActiveClassName);
                    linkNode.innerHTML = linkNode.getAttribute(attrLinkToResponsive);
                    $(".HeaderWrap").addClass("ResponsiveHeaderWrap");
                    $(".HeaderTopSpacer").addClass("ResponsiveHeaderTopSpacer");
                }

                // Switch link to RESPONSIVE version
                else if (
                    document.documentElement &&
                    (document.documentElement.clientWidth < responsiveSwitchBreakpoint ||
                        linkNode.getAttribute(attrAlwaysVisible) == "true")
                ) {
                    addClass(linkNode, linkToDesktopClassName);
                    addClass(linkNode, linkActiveClassName);
                    linkNode.innerHTML = linkNode.getAttribute(attrLinkToDesktop);
                    $(".HeaderWrap").removeClass("ResponsiveHeaderWrap");
                    $(".HeaderTopSpacer").removeClass("ResponsiveHeaderTopSpacer");
                }

                // Hide link
                else {
                    addClass(linkNode, linkInactiveClassName);
                    linkNode.innerHTML = "";
                    $(".HeaderWrap").removeClass("ResponsiveHeaderWrap");
                    $(".HeaderTopSpacer").removeClass("ResponsiveHeaderTopSpacer");
                }

                // Bind event listener
                if (!linksBound) {
                    linkNode.addEventListener(
                        "click",
                        function (e) {
                            e = e || window.event;
                            e.preventDefault();
                            toggle();
                        },
                        false,
                    );
                }
            }
        }
        linksBound = true;
    }

    // Toggle viewport and links
    function toggle() {
        rsViewport(true);
        rsLink();
    }

    function toggleDesktop() {
        rsViewportDesktopOnly();
        rsLink();
    }

    // Intializes behaviour
    function init() {
        var force = true;
        if (typeof sd_ForceDesktop === "undefined") {
            force = false;
        }
        // Set viewport (cookie based)
        if (isCookieSet()) {
            rsViewport(true);
        } else if (force) {
            rsViewport(false);
        }

        // Set link title & bind event listener
        if (document.addEventListener) {
            document.addEventListener("DOMContentLoaded", rsLink, false);
            document.addEventListener("orientationchange", rsLink, false);
            window.addEventListener("resize", rsLink, false);
        }
    }

    // HELPER: Checks whether cookie set
    function isCookieSet() {
        return document.cookie.search(rsLinkCookieName + "=true") > -1;
    }

    // HELPER: Adds class name to node
    function addClass(node, classToAdd) {
        node.className += " " + classToAdd;
    }

    // HELPER: Removes class name from node
    function removeClass(node, classToRemove) {
        node.className = " " + node.className + " ";
        node.className = node.className.replace(" " + classToRemove + " ", "");
    }

    // HELPER: Returns reload URL
    function getReloadUrl() {
        var date = new Date();
        var tmpVar = date.getTime();
        return (
            document.location.protocol +
            "//" +
            document.location.host +
            document.location.pathname +
            (window.location.search ? window.location.search + "&" : "?") +
            "rsTimestamp=" +
            tmpVar +
            document.location.hash
        );
    }

    // Initialize the whole shebang
    init();

    // PUBLIC API methods
    return {
        toggle: toggle,
        toggleDesktop: toggleDesktop,
    };
})();
jQuery(document).ready(function ($) {
    // if re-direct comes from standalone mobile site - auto set to desktop mode
    if (location.search.indexOf("stop_mobi=") >= 0) {
        responsiveSwitch.toggleDesktop();
    }
});
