/*
 
    To use this feature each element that needs to be lazily faded in needs to have a class of lazyFadeIn 
    and the skin.css and this js file referenced.

    This will show any elements visible on the page at initial load time.
    It will load the rest as each is one third visible on the screen.

    This uses the following PortalSettings

    LazyFadeInInitialPixelsVisible
    LazyFadeInSpeed
    LazyFadeInPixelsVisible

    You will need to include the following theme object into whichever layout you include sdLazyFadeIn to

    <%@ Register TagPrefix="sd" TagName="SD_LAZYFADEINATTRIBUEINJECTOR" Src="~/DesktopModules/SportsDirect/HelperSkinObjects/LazyFadeInAttributeInjector.ascx" %>
    <sd:SD_LAZYFADEINATTRIBUEINJECTOR id="LazyFadeInAttributeInjector" runat="server" />

 
 */

$(function () {
    var lazyFadeInAttrs = $("#lazyFadeInAttrs");
    if (lazyFadeInAttrs.length !== 1) return;

    var initialPixelsVisible = parseInt($("#lazyFadeInAttrs").data("initialpixelsvisible"));
    var speed = parseInt($("#lazyFadeInAttrs").data("speed"));
    var pixelsVisible = parseInt($("#lazyFadeInAttrs").data("pixelsvisible"));

    // Fade in any already on the screen
    initialCustomFadeInLoad();

    var lazyCount = $(".lazyFadeIn").length;

    // Lazy Load
    $(window).scroll(function () {
        if (lazyCount > 0) {
            customFadeIn();
        }
    });

    function initialCustomFadeInLoad() {
        /* Check the location of each desired element */
        $(".lazyFadeIn").each(function (i) {
            // Only initially fade in objects that have at least Ypx (set in Portal Settings) on screen.
            var top_of_object = $(this).offset().top + initialPixelsVisible;
            var bottom_of_window = $(window).scrollTop() + $(window).height();

            /* If any part of the object is visible in the window, display it */
            if (bottom_of_window > top_of_object) {
                $(this).css("opacity", 1);
                $(this).removeClass("lazyFadeIn");
            }
        });
    }

    function customFadeIn() {
        /* Check the location of each desired element */
        $(".lazyFadeIn").each(function (i) {
            // When Ypx (set in Portal Settings) of the lazyFadeIn element
            // is displayed on the screen then fade it in.
            var bottom_of_object = $(this).offset().top + pixelsVisible;
            var bottom_of_window = $(window).scrollTop() + $(window).height();

            /*
             * If enough of the object is visible in the window, fade it in
             * Fade in at speed in portal settings
             */
            if (bottom_of_window > bottom_of_object) {
                $(this).animate({ opacity: "1" }, speed);
                $(this).removeClass("lazyFadeIn");
            }
        });
    }
});
