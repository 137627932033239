/*!
 * classie - class helper functions
 * from bonzo https://github.com/ded/bonzo
 *
 * classie.has( elem, 'my-class' ) -> true/false
 * classie.add( elem, 'my-new-class' )
 * classie.remove( elem, 'my-unwanted-class' )
 * classie.toggle( elem, 'my-class' )
 */
!(function (e) {
    "use strict";
    function t(e) {
        return new RegExp("(^|\\s+)" + e + "(\\s+|$)");
    }
    function l(e, t) {
        var l = s(e, t) ? n : i;
        l(e, t);
    }
    var s, i, n;
    "classList" in document.documentElement
        ? ((s = function (e, t) {
              return e.classList.contains(t);
          }),
          (i = function (e, t) {
              void 0 != e.classList && e.classList.add(t);
          }),
          (n = function (e, t) {
              e.classList.remove(t);
          }))
        : ((s = function (e, l) {
              return t(l).test(e.className);
          }),
          (i = function (e, t) {
              s(e, t) || (e.className = e.className + " " + t);
          }),
          (n = function (e, l) {
              e.className = e.className.replace(t(l), " ");
          }));
    var a = { hasClass: s, addClass: i, removeClass: n, toggleClass: l, has: s, add: i, remove: n, toggle: l };
    "function" == typeof define && define.amd ? define(a) : (e.classie = a);
})(window),
    (function (e) {
        "use strict";
        function t(e, t) {
            for (var l in t) t.hasOwnProperty(l) && (e[l] = t[l]);
            return e;
        }
        function l(e, t) {
            if (!e) return !1;
            for (var l = e.target || e.srcElement || e || !1; l && l.id != t; ) l = l.parentNode || !1;
            return l !== !1;
        }
        function s(e, t, l, i) {
            return (
                (i = i || 0),
                e.id.indexOf(t) >= 0 ? i : (classie.has(e, l) && ++i, e.parentNode && s(e.parentNode, t, l, i))
            );
        }
        function i(e, t) {
            return classie.has(e, t) ? e : e.parentNode && i(e.parentNode, t);
        }
        function n(t) {
            $("html, .BodyWrap, .mp-level-active").css("height", "");
            var l = 0;
            (l = t ? $(".MobileMenuContentWrap").height() : $(".mp-level-opening").height()),
                $(e).height() > l && (l = $(e).height()),
                $("html, .BodyWrap, .mp-level-opening").css("height", l);
        }
        function a(e, l, s) {
            (this.el = e), (this.trigger = l), (this.options = t(this.defaults, s)), this._init();
        }
        (a.prototype = {
            defaults: { type: "overlap", levelSpacing: 40, backClass: "mp-back" },
            _init: function () {
                (this.open = !1),
                    (this.level = 0),
                    (this.wrapper = document.getElementById("mp-pusher")),
                    (this.levels = Array.prototype.slice.call(this.el.querySelectorAll("div.mp-level")));
                var e = this;
                this.levels.forEach(function (t) {
                    t.setAttribute("data-level", s(t, e.el.id, "mp-level"));
                }),
                    (this.menuItems = Array.prototype.slice.call(this.el.querySelectorAll("li"))),
                    (this.levelBack = Array.prototype.slice.call(
                        this.el.querySelectorAll("." + this.options.backClass),
                    )),
                    classie.add(this.el, "mp-" + this.options.type),
                    this._initEvents();
            },
            _initEvents: function () {
                function t(e) {
                    e.stopPropagation(),
                        e.preventDefault(),
                        s.open
                            ? s._resetMenu()
                            : ($("html, body").animate({ scrollTop: 0 }, "fast"),
                              s._openMenu(),
                              document.addEventListener("click", function (e) {
                                  s.open &&
                                      !l(e.target, s.el.id) &&
                                      0 == $(e.target).closest(".MobSearchDropDown").length &&
                                      (e.preventDefault(), n(this));
                              }));
                }
                var s = this,
                    n = function () {
                        s._resetMenu(), document.removeEventListener("click", n);
                    };
                this.trigger.addEventListener("click", t);
                var a = $("#mobileSearchTrigger");
                a.length > 0 &&
                    a[0].addEventListener("click", function (e) {
                        t(e), $("#MobtxtSearch").focus();
                    }),
                    this.menuItems.forEach(function (e) {
                        var t = "a";
                        null == e.querySelector("a") && (t = "span.currencyOption"),
                            e.querySelector(t).addEventListener("click", function (t) {
                                var l = e.querySelector("div.mp-level");
                                if (l) {
                                    t.preventDefault();
                                    var n = i(e, "mp-level").getAttribute("data-level");
                                    s.level <= n &&
                                        (classie.add(i(e, "mp-level"), "mp-level-overlay"),
                                        s._openMenu(l),
                                        $("html, body").animate({ scrollTop: 0 }, "fast"));
                                } else $(".mp-level-opening").length > 0 && t.preventDefault();
                            });
                    });
                var o = $(e).width();
                e.addEventListener("resize", function () {
                    if (
                        $(e).width() != o &&
                        ((o = $(e).width()),
                        $(".mp-level-open").length > 0 && s._openMenu($(".mp-level-open")),
                        $("#topMenuWrapper").is(":visible") && (s._resetMenu(), 0 != $("#topMenu").length))
                    ) {
                        var t = $("#topMenu").offset().left,
                            l = $("#topMenu").outerWidth();
                        $(".DropdownWrap").each(function () {
                            $(this).css(
                                $(this).parent().hasClass("AutoWidth")
                                    ? { left: "auto", width: "auto" }
                                    : { left: t, width: l },
                            );
                        });
                    }
                }),
                    this.levelBack.forEach(function (e) {
                        e.addEventListener("click", function (t) {
                            t.preventDefault(), t.stopPropagation();
                            var l = i(e, "mp-level").getAttribute("data-level");
                            s.level <= l &&
                                ((s.level = i(e, "mp-level").getAttribute("data-level") - 1),
                                0 === s.level ? s._resetMenu() : s._closeMenu());
                        });
                    });
            },
            _openMenu: function (e) {
                var t = !1;
                ++this.level;
                var l = (this.level - 1) * this.options.levelSpacing,
                    s = "overlap" === this.options.type ? this.el.offsetWidth + l : this.el.offsetWidth;
                if ((this._setTransform("translate(" + s + "px,0)"), e)) {
                    this._setTransform("", e);
                    for (var i = 0, a = this.levels.length; a > i; ++i) {
                        var o = this.levels[i];
                        o == e ||
                            classie.has(o, "mp-level-open") ||
                            this._setTransform("translate(-100%,0) translate(" + -1 * l + "px,0)", o);
                    }
                }
                1 === this.level && (classie.add(this.wrapper, "mp-pushed"), (this.open = !0), (t = !0)),
                    classie.add(e || this.levels[0], "mp-level-opening"),
                    classie.add(e || this.levels[0], "mp-level-active"),
                    setTimeout(function () {
                        $(".mp-level-opening").addClass("mp-level-open"),
                            $(".mp-level-opening").removeClass("mp-level-opening");
                    }, 500),
                    n(t);
            },
            _resetMenu: function () {
                this._setTransform("translate(0,0)"),
                    (this.level = 0),
                    classie.remove(this.wrapper, "mp-pushed"),
                    this._toggleLevels(),
                    (this.open = !1),
                    $("#mp-pusher").removeAttr("style"),
                    $("html, .BodyWrap, .mp-level-active").css("height", "");
            },
            _closeMenu: function () {
                var t =
                    "overlap" === this.options.type
                        ? this.el.offsetWidth + (this.level - 1) * this.options.levelSpacing
                        : this.el.offsetWidth;
                this._setTransform("translate(" + t + "px,0)"), this._toggleLevels();
                var l = $(".mp-level-active").height();
                $(e).height() > l && (l = $(e).height()), $("html, .BodyWrap, .mp-level-active").css("height", l);
            },
            _setTransform: function (e, t) {
                (t = t || this.wrapper),
                    void 0 != t.style &&
                        ((t.style.WebkitTransform = e), (t.style.MozTransform = e), (t.style.transform = e));
            },
            _toggleLevels: function () {
                for (var e = 0, t = this.levels.length; t > e; ++e) {
                    var l = this.levels[e];
                    l.getAttribute("data-level") >= this.level + 1
                        ? (classie.remove(l, "mp-level-open"),
                          classie.remove(l, "mp-level-active"),
                          classie.remove(l, "mp-level-overlay"))
                        : Number(l.getAttribute("data-level")) == this.level &&
                          (classie.remove(l, "mp-level-overlay"),
                          classie.add(l, "mp-level-opening"),
                          setTimeout(function () {
                              $(".mp-level-opening").addClass("mp-level-open"),
                                  $(".mp-level-opening").removeClass("mp-level-opening");
                          }, 500));
                }
            },
        }),
            (e.mlPushMenu = a);
    })(window);
